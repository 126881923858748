import React from 'react'
import ReactDOM from 'react-dom/client'
import {ContextWrapper} from "./App";


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    /*<React.StrictMode>*/

                    <ContextWrapper/>

    /*</React.StrictMode>*/
)
